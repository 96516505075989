import PropTypes from "prop-types";
import React, { useEffect, useRef, useCallback } from "react";
//Import Icons
import FeatherIcon from "feather-icons-react";
// //Import Scrollbar
import SimpleBar from "simplebar-react";
// MetisMenu

import HasAnyPermission from "../../common/Permission";

import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
//i18n
import { withTranslation } from "react-i18next";

const SidebarContent = (props) => {
	const ref = useRef();
	const activateParentDropdown = useCallback((item) => {
		item.classList.add("active");
		const parent = item.parentElement;
		const parent2El = parent.childNodes[1];
		if (parent2El && parent2El.id !== "side-menu") {
			parent2El.classList.add("mm-show");
		}
		if (parent) {
			parent.classList.add("mm-active");
			const parent2 = parent.parentElement;
			if (parent2) {
				parent2.classList.add("mm-show"); // ul tag
				const parent3 = parent2.parentElement; // li tag
				if (parent3) {
					parent3.classList.add("mm-active"); // li
					parent3.childNodes[0].classList.add("mm-active"); //a
					const parent4 = parent3.parentElement; // ul
					if (parent4) {
						parent4.classList.add("mm-show"); // ul
						const parent5 = parent4.parentElement;
						if (parent5) {
							parent5.classList.add("mm-show"); // li
							parent5.childNodes[0].classList.add("mm-active"); // a tag
						}
					}
				}
			}
			scrollElement(item);
			return false;
		}
		scrollElement(item);
		return false;
	}, []);

	useEffect(() => {
		const pathName = props.location.pathname;

		const initMenu = () => {
			new MetisMenu("#side-menu");
			let matchingMenuItem = null;
			const ul = document.getElementById("side-menu");
			const items = ul.getElementsByTagName("a");
			for (let i = 0; i < items.length; ++i) {
				if (pathName === items[i].pathname) {
					matchingMenuItem = items[i];
					break;
				}
			}
			if (matchingMenuItem) {
				activateParentDropdown(matchingMenuItem);
			}
		};
		initMenu();
	}, [props.location.pathname, activateParentDropdown]);

	useEffect(() => {
		ref.current.recalculate();
	});

	function scrollElement(item) {
		if (item) {
			const currentPosition = item.offsetTop;
			if (currentPosition > window.innerHeight) {
				ref.current.getScrollElement().scrollTop =
					currentPosition - 300;
			}
		}
	}

	return (
		<React.Fragment>
			<SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
				<div id="sidebar-menu">
					<ul className="metismenu list-unstyled" id="side-menu">
						<li className="menu-title">{props.t("Menu")} </li>
						<li>
							<Link to="/dashboard" className="">
								<i className="fas fa-home"></i>
								<span>{props.t("Dashboard")}</span>
							</Link>
						</li>

						<li>
							<HasAnyPermission
								permission={["user list", "user view"]}
							>
								<Link to="/users" className="">
									<i className="fas fa-user"></i>
									<span>{props.t("Users")}</span>
								</Link>
							</HasAnyPermission>
						</li>

						<li>
							<HasAnyPermission
								permission={[
									"product option list",
									"product option view",
								]}
							>
								<Link to="/shop/product-option" className="">
									<i className="fas fa-retweet"></i>
									<span>{props.t("Product Option")}</span>
								</Link>
							</HasAnyPermission>
						</li>

						<li>
							<HasAnyPermission
								permission={["product list", "product view"]}
							>
								<Link to="/shop/products" className="">
									<i className="fas fa-list"></i>
									<span>{props.t("Product")}</span>
								</Link>
							</HasAnyPermission>
						</li>

						<li>
							<HasAnyPermission
								permission={["order list", "order view"]}
							>
								<Link to="/shop/orders" className="">
									<i className="fas fa-shopping-bag"></i>
									<span>{props.t("Orders")}</span>
								</Link>
							</HasAnyPermission>
						</li>

						<li>
							<HasAnyPermission
								permission={[
									"cart order list",
									"cart order view",
								]}
							>
								<Link to="/shop/cart-orders" className="">
									<i className="fas fa-list"></i>
									<span>{props.t("Cart orders")}</span>
								</Link>
							</HasAnyPermission>
						</li>

						<li>
							<HasAnyPermission
								permission={["tax view", "tax list"]}
							>
								<Link
									to="/#"
									className="has-arrow"
									aria-expanded={false}
								>
									<i className="fas fa-money-bill"></i>
									<span>{props.t("Tax")}</span>
								</Link>
								<ul className="sub-menu">
									<li>
										<HasAnyPermission
											permission={[
												"tax view",
												"tax list",
											]}
										>
											<Link to="/shop/tax" className="">
												<span>{props.t("Tax")}</span>
											</Link>
										</HasAnyPermission>
									</li>
									<li>
										<HasAnyPermission
											permission={[
												"tax view",
												"tax list",
											]}
										>
											<Link
												to="/shop/tax/country"
												className=""
											>
												<span>
													{props.t("Country tax")}
												</span>
											</Link>
										</HasAnyPermission>
									</li>
								</ul>
							</HasAnyPermission>
						</li>

						<li>
							<HasAnyPermission
								permission={[
									"fees add",
									"fees list",
									"fees view",
								]}
							>
								<Link
									to="/#"
									className="has-arrow"
									aria-expanded={false}
								>
									<i className="fas fa-hand-holding-usd"></i>
									<span>{props.t("Fees")}</span>
								</Link>
								<ul className="sub-menu">
									<li>
										<HasAnyPermission
											permission={[
												"fees add",
												"fees list",
												"fees view",
											]}
										>
											<Link to="/shop/fee" className="">
												<span>{props.t("Fee")}</span>
											</Link>
										</HasAnyPermission>
									</li>
									<li>
										<HasAnyPermission
											permission={[
												"fees add",
												"fees list",
												"fees view",
											]}
										>
											<Link
												to="/shop/fee/country"
												className=""
											>
												<span>
													{props.t("Country Fee")}
												</span>
											</Link>
										</HasAnyPermission>
									</li>
								</ul>
							</HasAnyPermission>
						</li>

						<li>
							<HasAnyPermission
								permission={[
									"shipping setting view",
									"shipping setting list",
								]}
							>
								<Link
									to="/#"
									className="has-arrow"
									aria-expanded={false}
								>
									<i className="fas fa-wrench"></i>
									<span>{props.t("Shipping Settings")}</span>
								</Link>
								<ul className="sub-menu">
									<li>
										<HasAnyPermission
											permission={[
												"shipping setting view",
												"shipping setting list",
											]}
										>
											<Link
												to="/shipping/setting"
												className=""
											>
												<span>
													{props.t(
														"Shipping Setting"
													)}
												</span>
											</Link>
										</HasAnyPermission>
									</li>
									<li>
										<HasAnyPermission
											permission={[
												"shipping setting view",
												"shipping setting list",
											]}
										>
											<Link
												to="/shipping/fee"
												className=""
											>
												<span>
													{props.t(
														"Countrywise shipping Fee"
													)}
												</span>
											</Link>
										</HasAnyPermission>
									</li>
								</ul>
							</HasAnyPermission>
						</li>

						<li>
							<HasAnyPermission
								permission={[
									"admin add",
									"admin list",
									"role add",
									"role list",
								]}
							>
								<li>
									<Link
										to="/#"
										className="has-arrow"
										aria-expanded={false}
									>
										<i className="fas fa-cogs"></i>
										<span>{props.t("Admin & Role")}</span>
									</Link>
									<ul className="sub-menu">
										<HasAnyPermission
											permission={[
												"admin add",
												"admin list",
											]}
										>
											<li>
												<Link to="/admins" className="">
													<span>
														{props.t("Admin")}
													</span>
												</Link>
											</li>
										</HasAnyPermission>
										<HasAnyPermission
											permission={[
												"role add",
												"role list",
											]}
										>
											<li>
												<Link
													to="/roles"
													className=""
													aria-expanded={false}
												>
													<span>
														{props.t("Roles")}
													</span>
												</Link>
											</li>
										</HasAnyPermission>
									</ul>
								</li>
							</HasAnyPermission>
						</li>

						<li>
							<HasAnyPermission
								permission={[
									"customer shipping address log list",
								]}
							>
								<Link to="/address-log" className="">
									<i className="fas fa-history"></i>
									<span>{props.t("Log")}</span>
								</Link>
							</HasAnyPermission>
						</li>

						<li>
							<HasAnyPermission
								permission={[
									"customer shipping address log list",
								]}
							>
								<Link to="/presale-log" className="">
									<i className="fas fa-history"></i>
									<span>
										{props.t("Pre-Sale Shipping Log")}
									</span>
								</Link>
							</HasAnyPermission>
						</li>
					</ul>
				</div>
			</SimpleBar>
		</React.Fragment>
	);
};

SidebarContent.propTypes = {
	location: PropTypes.object,
	t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
