import React from "react";
import { Redirect } from "react-router-dom";
import Dashboard from "../pages/Dashboard/index";
import Admin from "../pages/Admin";
import User from "../pages/User";
import UserDetails from "../pages/User/UserDetails";
import Role from "../pages/Admin/Role";
import userProfile from "../pages/Authentication/user-profile";
// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import ForgotPassword from "../pages/Authentication/ForgotPassword";
import Product from "../pages/Product";
import Order from "../pages/Order";
import OrderDetails from "../pages/Order/OrderDetails";
import VoucherCodePackage from "../pages/VoucherCodePackage";
import ProductOption from "../pages/ProductOption";
import Setting from "../pages/Shipping/Setting";
import ShippingFee from "../pages/Shipping/ShippingFee";
import Tax from "../pages/Tax";
import CountryTax from "../pages/Tax/CountryTax";
import Fee from "../pages/Fees";
import CartOrder from "../pages/CartOrder";
import AddressLog from "../pages/AddressLog";
import PresaleLog from "../pages/PresaleLog";

const userRoutes = [
	//dashboard
	{ path: "/dashboard", component: Dashboard },
	//profile
	{
		path: "/profile",
		component: userProfile,
	},
	{
		path: "/admins",
		exact: true,
		component: Admin,
		permission: ["admin add", "admin list"],
	},
	{
		path: "/users",
		exact: true,
		component: User,
		permission: ["user list", "user view"],
	},
	{
		path: "/shop/products",
		exact: true,
		component: Product,
		permission: ["product list", "product view"],
	},
	{
		path: "/shop/product-option",
		exact: true,
		component: ProductOption,
		permission: ["product option list", "product option view"],
	},
	{
		path: "/shop/orders",
		exact: true,
		component: Order,
		permission: ["order list", "order view"],
	},
	// {
	// 	path: "/shop/voucher-code-package",
	// 	exact: true,
	// 	component: VoucherCodePackage,
	// 	permission: ["admin add", "admin list"],
	// },
	{
		path: "/shop/order/:id",
		exact: true,
		component: OrderDetails,
		permission: ["order list", "order view"],
	},
	{
		path: "/shop/cart-orders",
		exact: true,
		component: CartOrder,
		permission: ["cart order list", "cart order view"],
	},
	{
		path: "/shop/tax",
		exact: true,
		component: Tax,
		permission: ["tax view", "tax list"],
	},
	{
		path: "/shop/fee",
		exact: true,
		component: Fee,
		permission: ["fees add", "fees list", "fees view"],
	},
	{
		path: "/shop/tax/country",
		exact: true,
		component: CountryTax,
		permission: ["fees add", "fees list", "fees view"],
	},
	{
		path: "/user/:_id",
		exact: true,
		component: UserDetails,
		permission: ["user view"],
	},
	{
		path: "/roles",
		exact: true,
		component: Role,
		permission: ["role add", "role list"],
	},

	{
		path: "/shipping/setting",
		exact: true,
		component: Setting,
		permission: ["shipping setting view", "shipping setting list"],
	},
	{
		path: "/shipping/fee",
		exact: true,
		component: ShippingFee,
		permission: ["shipping setting view", "shipping setting list"],
	},
	{
		path: "/address-log",
		exact: true,
		component: AddressLog,
		permission: ["admin add", "admin list"],
	},
	{
		path: "/presale-log",
		exact: true,
		component: PresaleLog,
		permission: ["admin add", "admin list"],
	},
	// this route should be at the end of all other routes
	{ path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const authRoutes = [
	//authencation page
	{ path: "/logout", exact: true, component: Logout },
	{ path: "/login", exact: true, component: Login },
	{
		path: "/forgot-password/:token/:tokenKey",
		exact: true,
		component: ForgotPassword,
	},
	{ path: "/forgot-password", exact: true, component: ForgotPassword },
];

export { userRoutes, authRoutes };
