export const ERRORS = "ERRORS";
export const CLEAR_RESPONSE = "CLEAR_RESPONSE";

export const CREATE_ORDER_REQUEST = "CREATE_ORDER_REQUEST";
export const CREATE_ORDER_SUCCESS = "CREATE_ORDER_SUCCESS";
export const CREATE_ORDER_FAILURE = "CREATE_ORDER_FAILURE";

export const UPDATE_ORDER_REQUEST = "UPDATE_ORDER_REQUEST";
export const UPDATE_ORDER_SUCCESS = "UPDATE_ORDER_SUCCESS";
export const UPDATE_ORDER_FAILURE = "UPDATE_ORDER_FAILURE";

export const REMOVE_ORDER_REQUEST = "REMOVE_ORDER_REQUEST";
export const REMOVE_ORDER_SUCCESS = "REMOVE_ORDER_SUCCESS";
export const REMOVE_ORDER_FAILURE = "REMOVE_ORDER_FAILURE";

export const TOGGLE_ORDER_SUCCESS = "TOGGLE_ORDER_SUCCESS";
export const TOGGLE_ORDER_REQUEST = "TOGGLE_ORDER_REQUEST";
export const TOGGLE_ORDER_FAILURE = "TOGGLE_ORDER_FAILURE";

export const GET_ORDER_REQUEST = "GET_ORDER_REQUEST";
export const GET_ORDER_SUCCESS = "GET_ORDER_SUCCESS";
export const GET_ORDER_FAILURE = "GET_ORDER_FAILURE";

export const GET_ORDER_WITH_ID_REQUEST = "GET_ORDER_WITH_ID_REQUEST";
export const GET_ORDER_WITH_ID_SUCCESS = "GET_ORDER_WITH_ID_SUCCESS";
export const GET_ORDER_WITH_ID_FAILURE = "GET_ORDER_WITH_ID_FAILURE";

export const GET_MAC_ADDRESS_REQUEST = "GET_MAC_ADDRESS_REQUEST";
export const GET_MAC_ADDRESS_SUCCESS = "GET_MAC_ADDRESS_SUCCESS";
export const GET_MAC_ADDRESS_FAILURE = "GET_MAC_ADDRESS_FAILURE";

export const GET_INVOICE_REQUEST = "GET_INVOICE_REQUEST";
export const GET_INVOICE_SUCCESS = "GET_INVOICE_SUCCESS";
export const GET_INVOICE_FAILURE = "GET_INVOICE_FAILURE";
export const CLEAR_INVOICE_RESPONSE = "CLEAR_INVOICE_RESPONSE";

export const GET_UPGRADE_PRODUCT_REQUEST = "GET_UPGRADE_PRODUCT_REQUEST";
export const GET_UPGRADE_PRODUCT_SUCCESS = "GET_UPGRADE_PRODUCT_SUCCESS";
export const GET_UPGRADE_PRODUCT_FAILURE = "GET_UPGRADE_PRODUCT_FAILURE";
export const CLEAR_NOTIFICATION = "CLEAR_NOTIFICATION";

export const GET_UPGRADE_ORDER_PRODUCT_REQUEST =
	"GET_UPGRADE_ORDER_PRODUCT_REQUEST";
export const GET_UPGRADE_ORDER_PRODUCT_SUCCESS =
	"GET_UPGRADE_ORDER_PRODUCT_SUCCESS";
export const GET_UPGRADE_ORDER_PRODUCT_FAILURE =
	"GET_UPGRADE_ORDER_PRODUCT_FAILURE";

export const EXPORT_ORDER_REQUEST = "EXPORT_ORDER_REQUEST";
export const EXPORT_ORDER_SUCCESS = "EXPORT_ORDER_SUCCESS";
export const EXPORT_ORDER_FAILURE = "EXPORT_ORDER_FAILURE";

export const GET_ORDER_MERCHANT_INVOICE_REQUEST =
	"GET_ORDER_MERCHANT_INVOICE_REQUEST";
export const GET_ORDER_MERCHANT_INVOICE_SUCCESS =
	"GET_ORDER_MERCHANT_INVOICE_SUCCESS";
export const GET_ORDER_MERCHANT_INVOICE_FAILURE =
	"GET_ORDER_MERCHANT_INVOICE_FAILURE";

export const UPDATE_ORDER_STATUS_REQUEST = "UPDATE_ORDER_STATUS_REQUEST";
export const UPDATE_ORDER_STATUS_SUCCESS = "UPDATE_ORDER_STATUS_SUCCESS";
export const UPDATE_ORDER_STATUS_FAILURE = "UPDATE_ORDER_STATUS_FAILURE";

export const UPDATE_ORDER_CHANGE_ADDRESS_SUCCESS =
	"UPDATE_ORDER_CHANGE_ADDRESS_SUCCESS";
export const UPDATE_ORDER_CHANGE_ADDRESS_REQUEST =
	"UPDATE_ORDER_CHANGE_ADDRESS_REQUEST";
export const UPDATE_ORDER_CHANGE_ADDRESS_FAILURE =
	"UPDATE_ORDER_CHANGE_ADDRESS_FAILURE";

export const UPDATE_ORDER_CHANGE_METHOD_SUCCESS =
	"UPDATE_ORDER_CHANGE_METHOD_SUCCESS";
export const UPDATE_ORDER_CHANGE_METHOD_REQUEST =
	"UPDATE_ORDER_CHANGE_METHOD_REQUEST";
export const UPDATE_ORDER_CHANGE_METHOD_FAILURE =
	"UPDATE_ORDER_CHANGE_METHOD_FAILURE";

export const UPDATE_SHIPPING_DETAILS_SUCCESS =
	"UPDATE_SHIPPING_DETAILS_SUCCESS";
export const UPDATE_SHIPPING_DETAILS_REQUEST =
	"UPDATE_SHIPPING_DETAILS_REQUEST";
export const UPDATE_SHIPPING_DETAILS_FAILURE =
	"UPDATE_SHIPPING_DETAILS_FAILURE";
