import React, { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import {
	Card,
	CardBody,
	Col,
	Container,
	Row,
	TableModal,
	Modal,
	ModalHeader,
	ModalBody,
	Table,
} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
//Import Image
import logo from "../../assets/images/logo-sm.svg";
import { getOrder, getOrderMerchantInvoice } from "../../store/order/actions";
//redux
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { toast } from "react-toastify";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import Swal from "sweetalert2";
import isEmpty from "../../utils/isEmpty";
import OrderCallbackResponseModal from "./OrderCallbackResponseModal";

import {
	clearResponse,
	clearNotification,
	getInvoice,
	clearInvoice,
	getExportOrderCSV,
} from "../../store/order/actions";

const OrderDetails = (props) => {
	//meta title
	document.title = "Order Detail | LYOTECHLABS";
	const dispatch = useDispatch();
	const [isOpenInvoiceModal, toggleInvoiceModal] = useState(false);
	const { OrderDetails } = useSelector((state) => ({
		OrderDetails: state.order,
	}));
	const [isOpenOrderCallbackModal, setOrderCallbackModal] = useState(false);
	const { transactions, response, notification, invoice, order } =
		OrderDetails;
	const {
		match: { params },
	} = props;

	console.log("transactions", transactions);

	useEffect(() => {
		if (params?.id) {
			dispatch(getOrder({ id: params.id }));
			dispatch(getOrderMerchantInvoice({ orderId: params.id }));
		}
	}, [dispatch]);

	const handleOrderInvoice = () => {
		if (isOpenInvoiceModal === true) {
			dispatch(clearInvoice());
		}
		toggleInvoiceModal(!isOpenInvoiceModal);
	};

	useEffect(() => {
		Swal?.close();
		if (invoice?.orderId) {
			toggleInvoiceModal(true);
		}
	}, [invoice]);

	//Print the Invoice
	const printInvoice = () => {
		window.print();
	};
	const handleTooltipOpen = () => {
		toast.success("Copied to clipboard.");
	};

	const getOrGenerateInvoice = (order) => {
		Swal.fire({
			title: "Please wait...",
			html: "Please wait...",
			allowEscapeKey: false,
			allowOutsideClick: false,
			didOpen: () => {
				Swal.showLoading();
			},
		});
		dispatch(getInvoice({ id: order.orderId }));
	};
	const orderDetails = order.details;

	const getUserName = useMemo(() => {
		return order?.userId?.firstName
			? `${order.userId.firstName} ${order.userId.lastName}`
			: orderDetails?.billingAddress
			? `${orderDetails.billingAddress.firstName} ${orderDetails.billingAddress.lastName}`
			: "-";
	}, [order]);

	const getUserEmail = useMemo(() => {
		return order?.userId?.email
			? `${order.userId.email}`
			: orderDetails?.billingAddress
			? `${orderDetails.billingAddress.email}`
			: "-";
	}, [order]);

	return (
		<React.Fragment>
			<div className="page-content">
				<Container fluid>
					{/* Render Breadcrumbs */}
					<Breadcrumbs title="Orders" breadcrumbItem="Order Detail" />
					{!isEmpty(order) && (
						<Row>
							<Col lg="12">
								<Card>
									<CardBody>
										<div className="invoice-title">
											<div className="d-flex align-items-start">
												<div className="flex-grow-1">
													<div className="mb-4">
														<img
															src={""}
															alt=""
															height="24"
														/>
														<span className="logo-txt">
															{getUserName}
														</span>
														<span className="logo-txt">
															({getUserEmail}){" "}
														</span>
													</div>
													{/* {order.status ===
														"processing" ||
													order.status ===
														"completed" ? (
														<div className="mb-0">
															<button
																type="button"
																onClick={(
																	e
																) => {
																	e.preventDefault();
																	setOrderCallbackModal(
																		true
																	);
																}}
																className="btn btn-sm btn-info waves-effect waves-light"
															>
																View CallBack
																details
															</button>
														</div>
													) : null} */}
												</div>
												<div className="flex-shrink-0">
													<div className="mb-4">
														<h4 className="float-end font-size-16">
															Order ID #{" "}
															{order._id}
														</h4>
													</div>
												</div>
											</div>
											<address></address>
										</div>
										<hr className="my-4" />
										<Row>
											<Col sm="4">
												<div>
													<h5 className="font-size-15 mb-3">
														Billed To:
													</h5>
													<p>
														{
															orderDetails
																.billingAddress
																.firstName
														}{" "}
														{
															orderDetails
																.billingAddress
																.lastName
														}
													</p>

													<p>
														{
															orderDetails
																.billingAddress
																.address
														}{" "}
													</p>
													<p>
														{
															orderDetails
																.billingAddress
																.city
														}{" "}
													</p>
													<p>
														{
															orderDetails
																.billingAddress
																.country
														}{" "}
													</p>
													<p>
														Postal Code: &nbsp;
														{
															orderDetails
																.billingAddress
																?.postalCode
														}{" "}
													</p>
												</div>
											</Col>
											<Col sm="4">
												<div>
													<h5 className="font-size-15 mb-3">
														Shipping :
													</h5>
													<span>
														{
															orderDetails
																?.shippingAddress
																?.firstName
														}
													</span>
													&nbsp;{" "}
													<span>
														{
															orderDetails
																?.shippingAddress
																?.lastName
														}
													</span>
													<br />
													<span>
														{
															orderDetails
																?.shippingAddress
																?.address
														}
													</span>
													<br />
													<span>
														{
															orderDetails
																?.shippingAddress
																?.city
														}
													</span>
													, &nbsp;
													<span>
														{
															orderDetails
																?.shippingAddress
																?.state
														}
													</span>
													, &nbsp;
													<span>
														{
															orderDetails
																?.shippingAddress
																?.country
														}
													</span>
													<br />
													<span>
														{" "}
														Postal Code: &nbsp;
														{
															orderDetails
																?.shippingAddress
																?.postalCode
														}
													</span>
													<span>
														{
															orderDetails
																?.shippingAddress
																?.email
														}
													</span>
													<br />
													<span>
														{orderDetails
															?.shippingAddress
															?.countryCode
															? "+"
															: ""}
														{
															orderDetails
																?.shippingAddress
																?.countryCode
														}{" "}
														{
															orderDetails
																?.shippingAddress
																?.phone
														}
													</span>
												</div>
											</Col>
											<Col sm="4">
												<div>
													<div>
														<h5 className="font-size-15">
															Order Date:
														</h5>
														<p>
															{moment(
																order.createdAt
															).format("LLL")}
														</p>
													</div>
												</div>
												<div className="mt-4">
													<h5 className="font-size-15">
														Payment Method:
													</h5>
													<p className="mb-1">
														{
															order.paymentMethod
																?.name
														}{" "}
													</p>
												</div>

												<div className="mt-4">
													<div>
														<h5 className="font-size-15">
															Order Status:
														</h5>
														<p>
															{order.status ===
															"processing" ? (
																<div className="badge badge-soft-primary font-size-12">
																	Processing
																</div>
															) : order.status ===
															  "pending" ? (
																<div className="badge badge-soft-warning font-size-12">
																	Pending
																</div>
															) : order.status ===
															  "completed" ? (
																<div className="badge badge-soft-success font-size-12">
																	Completed
																</div>
															) : (
																<div className="badge badge-soft-danger font-size-12">
																	{
																		order.status
																	}
																</div>
															)}
														</p>
													</div>
												</div>
											</Col>
										</Row>

										<div className="py-2 mt-3">
											<h3 className="font-size-15 fw-bold">
												Transactions
											</h3>
										</div>
										<div className="p-4 border rounded">
											<div className="table-responsive">
												<Table className="table table-hover mb-0">
													<thead>
														<tr>
															<th>#</th>
															<th>Network</th>
															<th>Address</th>
															<th>Amount</th>
															<th>Status</th>
															<th>Scan URL</th>
														</tr>
													</thead>
													<tbody>
														{transactions.isLoading ? (
															<tr>
																<td
																	className="text-center"
																	colSpan={6}
																>
																	Loading...
																</td>
															</tr>
														) : transactions
																.details?.[0] ? (
															transactions.details.map(
																(
																	transaction,
																	index
																) => (
																	<tr
																		key={
																			index
																		}
																	>
																		<th scope="row">
																			{index +
																				1}
																		</th>
																		<td>
																			{
																				transaction.network
																			}
																		</td>
																		<td>
																			<div className="text-nowrap">
																				<CopyToClipboard
																					data-tooltip-content={
																						transaction.address
																					}
																					id={`transactiontooltip_${index}`}
																					text={
																						transaction.address
																					}
																					style={{
																						cursor: "pointer",
																					}}
																					onCopy={() =>
																						handleTooltipOpen()
																					}
																				>
																					<span
																						style={{
																							cursor: "pointer",
																						}}
																					>
																						{`${transaction.address} `}
																						<i className="mdi mdi-content-copy"></i>
																					</span>
																				</CopyToClipboard>

																				<ReactTooltip
																					anchorId={`transactiontooltip_${index}`}
																					variant="info"
																					place="bottom"
																					style={{
																						zIndex: 2147483647,
																					}}
																				/>
																			</div>
																		</td>
																		<td>
																			{`${transaction.amount} ${transaction.coin}`}
																		</td>
																		<td>
																			<div
																				className={`badge badge-soft-${
																					transaction.status ===
																					"Pending"
																						? "warning"
																						: transaction.status ===
																						  "Partial"
																						? "primary"
																						: transaction.status ===
																								"Cancelled" ||
																						  transaction.status ===
																								"Expired"
																						? "danger"
																						: "success"
																				} font-size-12`}
																			>
																				{
																					transaction.status
																				}
																			</div>
																		</td>
																		<td>
																			<a
																				href={
																					transaction.scanUrl
																				}
																				target="_blank"
																			>
																				{
																					transaction.scanUrl
																				}
																			</a>
																		</td>
																	</tr>
																)
															)
														) : (
															<tr>
																<td
																	className="text-center"
																	colSpan={6}
																>
																	No
																	transactions
																	available
																</td>
															</tr>
														)}
													</tbody>
												</Table>
											</div>
										</div>

										<div className="py-2 mt-3">
											<h3 className="font-size-15 fw-bold">
												Order summary
											</h3>
										</div>
										<div className="p-4 border rounded">
											<div className="table-responsive">
												<Table className="table-nowrap align-middle mb-0">
													<thead>
														<tr>
															<th
																style={{
																	width: "70px",
																}}
															>
																No.
															</th>
															<th>Item</th>
															<th
																className="text-end"
																style={{
																	width: "120px",
																}}
															>
																Price
															</th>

															<th
																className="text-end"
																style={{
																	width: "120px",
																}}
															>
																Quantity
															</th>

															<th
																className="text-end"
																style={{
																	width: "120px",
																}}
															>
																{/* TAX */}
															</th>

															<th
																className="text-end"
																style={{
																	width: "120px",
																}}
															>
																Total
															</th>
														</tr>
													</thead>
													<tbody>
														{order.products?.map(
															(item, key) => (
																<tr key={key}>
																	<td>
																		{key +
																			1}
																	</td>
																	<td>
																		<h5 className="font-size-15 mb-1">
																			{
																				item
																					.productId
																					?.name
																			}
																		</h5>
																	</td>
																	<td className="text-end">
																		{
																			item.orderPrice
																		}
																	</td>

																	<td className="text-end">
																		<h5 className="font-size-15 mb-1">
																			{
																				item.quantity
																			}
																		</h5>
																	</td>

																	<td className="text-end">
																		<h5 className="font-size-15 mb-1">
																			{/* {
																				item.total_tax
																			} */}
																		</h5>
																	</td>

																	<td className="text-end">
																		{parseFloat(
																			item.orderPrice *
																				item.quantity
																		).toFixed(
																			2
																		)}
																	</td>
																</tr>
															)
														)}

														{orderDetails.fees?.map(
															(item, key) => (
																<tr
																	key={
																		item._id
																	}
																>
																	<td
																		colSpan="5"
																		className="border-0 text-end"
																	>
																		<strong>
																			{
																				item.type
																			}
																		</strong>
																	</td>
																	<td className="border-0 text-end">
																		{
																			item.fee
																		}
																	</td>
																</tr>
															)
														)}

														{order.taxes?.map(
															(item, key) => (
																<tr>
																	<td
																		colSpan="5"
																		className="border-0 text-end"
																	>
																		<strong>
																			{
																				item
																					.tax
																					?.name
																			}
																		</strong>
																	</td>
																	<td className="border-0 text-end">
																		{
																			item.taxValue
																		}
																	</td>
																</tr>
															)
														)}

														<tr>
															<td
																colSpan="5"
																className="border-0 text-end"
															>
																<strong>
																	Total
																</strong>
															</td>
															<td className="border-0 text-end">
																<h4 className="m-0">
																	{
																		order.totalAmount
																	}
																</h4>
															</td>
														</tr>
													</tbody>
												</Table>
											</div>
										</div>
										{/* <div className="d-print-none mt-3">
											<div className="float-end">
												<Link
													to="#"
													onClick={() =>
														getOrGenerateInvoice({
															orderId: order.id,
														})
													}
													className="btn btn-success me-2"
												>
													<i className="fa fa-print" />
												</Link>
											</div>
										</div> */}
									</CardBody>
								</Card>
							</Col>
							{/* <Modal
								isOpen={isOpenInvoiceModal}
								toggle={() => handleOrderInvoice()}
								size="xl"
								centered={true}
							>
								<ModalHeader
									toggle={() => handleOrderInvoice()}
									tag="h4"
								>
									Invoice Details
								</ModalHeader>
								<ModalBody style={{ height: "80vh" }}>
									<Row>
										<Col xl="12" md="12" lg="12">
											{invoice?.file?.link && (
												<iframe
													src={invoice.file.link}
													title="PDF"
													style={{
														height: "75vh",
														width: "100%",
													}}
												/>
											)}
										</Col>
									</Row>
								</ModalBody>
							</Modal> */}

							{isOpenOrderCallbackModal && (
								<OrderCallbackResponseModal
									isOpenOrderCallbackModal={
										isOpenOrderCallbackModal
									}
									setOrderCallbackModal={
										setOrderCallbackModal
									}
									details={
										order?.orderCallback
											? order.orderCallback
											: null
									}
								/>
							)}
						</Row>
					)}
				</Container>
			</div>
		</React.Fragment>
	);
};

export default OrderDetails;
